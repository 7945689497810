import PublicIcon from "@mui/icons-material/Public";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const teams = [
  {
    type: "Team Cologne University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Oya Beyan",
        role: "HEAD",
        designation: "Chair Holder @Köln, Group Leader @FIT",
        image: "./assets/team/oya.jpeg",
        social: [
          {
            type: "Website",
            link: "http://oyabeyan.info",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Mehrshad Jaberansary",
        role: "Research Assistant Uniklinik Köln",
        designation: "Computer Science @RWTH",
        image: "./assets/team/mehrshad.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team FIT",
    active: true,
    members: [
      {
        name: "Yeliz Ucer Yediel",
        role: "Software Engineer M.Sc.",
        designation: "Product Management @FIT",
        image: "./assets/team/yeliz.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:yeliz.ucer.yediel@fit.fraunhofer.de",
            icon: <EmailIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/yelizucer",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Muhammad Hamza Akhtar",
        role: "Student Assistant",
        designation: "Data Science @RWTH",
        image: "./assets/team/hamza.jpg",
        social: [
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/mhamzaakhtar/",
            icon: <LinkedInIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team Leipzig University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Toralf Kirsten",
        role: "Contributor",
        designation: "Professor @Leipzig",
        image: "./assets/team/toralf.jpeg",
        social: [
          {
            type: "Email",
            link: "toralf.kirsten@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Masoud Abedi",
        role: "Machine & Deep Learning Methods",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/masoud.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:masoud.abedi@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Maximilian Jugl",
        role: "Cyber Security",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/maximilian.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:maximilian.jugl@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Lars Hempel",
        role: "Bioinformatics",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/lars.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:lars.hempel@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team RWTH University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Stefan Decker",
        role: "Director",
        designation: "Chair Holder @RWTH, Director @FIT",
        image: "./assets/team/decker.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:stefan.decker@fit.fraunhofer.de",
            icon: <EmailIcon />,
          },
        ],
      },
      {
        name: "Sascha Welten",
        role: "Computer Science M.Sc.",
        designation: "PhD Student @RWTH",
        image: "./assets/team/sascha.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:welten@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://dbis.rwth-aachen.de/dbis/index.php/user/welten/",
            icon: <PublicIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/sascha-welten-040b32b2",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Yongli Mou",
        role: "Computer Science M.Sc.",
        designation: "PhD Student @RWTH",
        image: "./assets/team/yongli.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:mou@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://dbis.rwth-aachen.de/dbis/index.php/user/mou/",
            icon: <PublicIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/mouyongli/040b32b2",
            icon: <LinkedInIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Alumni",
    active: false,
    members: [
      {
        name: "Laurenz Neumann",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/laurenz.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
      {
        name: "Sven Weber",
        role: "Research Assistant Uniklinik Köln",
        designation: "Master Student @ETH Zürich",
        image: "./assets/team/sven.jpeg",
        social: [
          {
            type: "LinkedIn",
            link: "https://de.linkedin.com/in/sven-weber-265736148",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Hauke Heidemeyer",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/heidemeyer.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
      {
        name: "Karl Kindermann",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/karl.jpg",
        social: [
          {
            type: "Email",
            link: "mailto:karl.kindermann@rwth-aachen.de",
            icon: <EmailIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/karlkindermann/",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Sabith Haneef",
        role: "Student Assistant",
        designation: "Software Systems Engineering @RWTH",
        image: "./assets/team/sabith.jpg",
        social: [
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/sabith-t-h-21a29716b/",
            icon: <LinkedInIcon />,
          },
        ],
      },
    ],
  },
];

export { teams };
